<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <div class="innerPage-banner heightCalc py-4 whiteBG">
            <div class="container-fluid h-100 calHeight">
                <div class="row h-100">
                    <div class="col-12 col-xl-3">
                        <!-- SearchBar -->
                        <economic-search></economic-search>
                        <div class="mainOverview rtNews p-3" v-if="store.newsList.totalRecords">
                            <h4 class="green mb-3 text-left">News</h4>
                            <div class="news border-bottom" v-for="item,index in store.newsList.records.slice(0,6)"
                                :key="index">
                                <h6><a href="javascript:void(0)">{{item.title}}</a></h6>
                                <p class="clock f-12 mb-2">
                                    <vue-feather type="clock" class="clock-icon"></vue-feather>
                                    {{formatNow(item.published,'now',0)}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-xl-9">
                        <div class="mainRightMap ltNews">
                            <economic-mainnavbar></economic-mainnavbar>
                            <div class="flexAuto">
                                <economic-map v-show="Object.keys(store.economicGDPData).length > 0"></economic-map>
                                <carousel :settings="settings" :breakpoints="breakpoints"
                                    v-if="store.newsList.records.length">
                                    <slide class="d-flex align-items-center"
                                        v-for="item,index in store.newsList.records.slice(6,store.newsList.records.length-1)"
                                        :key="index">
                                        <span class="flex-shrink-0 me-2 iconRate"><img :src="item.image" class="newsImg"
                                                alt="Generic image" width="80" height="80" /></span>
                                        <div class="flex-grow-1 text-start pe-2">
                                            <span class="medium f-14 d-block mb-2 line-clamp">{{item.title}}</span>
                                            <p class="clock d-flex align-items-center f-12 mb-2">
                                                <span class="d-flex align-items-center">
                                                    <vue-feather type="clock" class="clock-icon"></vue-feather>
                                                    {{formatNow(item.published,'now',0)}}
                                                </span>
                                                <span class="ml-3">
                                                    <a href="javascript:void(0)" class="fire"><img
                                                            src="assets/images/fire.svg" alt="Icon" /></a>
                                                </span>
                                            </p>
                                        </div>
                                    </slide>
                                    <template #addons>
                                        <navigation />
                                    </template>
                                </carousel>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-12">
                        <bottom-navbar></bottom-navbar>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide, Navigation, } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import economicmainnavbar from "./files/economy_mainnavbar";
import economicmap from "./files/economy_map";
// import bottomnavbar from "./files/bottom_navbar";
import economicsearch from "./files/economy_search";
import bannerslider from "../../components/shared/banner_slider";
import { useMainStore } from "@/store";
import moment from 'moment'

export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                575: {
                    itemsToShow: 2,
                    snapAlign: 'center',
                },
                // 1024 and up
                1024: {
                    itemsToShow: 3,
                    snapAlign: 'start',
                },
                // 1024 and up
                1500: {
                    itemsToShow: 4,
                    snapAlign: 'start',
                },
            },
        }
    },
    components: {
        Carousel,
        Slide,
        Navigation,
        bannerSlider: bannerslider,
        economicMap: economicmap,
        // bottomNavbar:bottomnavbar,
        economicSearch: economicsearch,
        economicMainnavbar: economicmainnavbar,

    },
    methods: {
        formatNow(date, format, offset) {
            if (!date) return 'N/A'
            let d = moment(date, 'YYYY-MM-DD HH:mm:ss')
            let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
            d.add(tz, 'minutes')
            return d.fromNow()
        },
        getLatestNews(country) {
            let formData = {
                page: 1,
                perPage: 12,
                country: [country]
            };
            this.store.callNewsList(formData, true).then(() => {
            }).catch(err => console.log(err.message))
        }
    },
    mounted() {
        if (this.$route.query.country) {
            this.getLatestNews(this.$route.query.country)
        }
    }
}
</script>
